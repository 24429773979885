/**
 * Validates the connection name.
 * Ensures that the name is not empty and contains only alphanumeric characters.
 *
 * @param {string} connectionName - The name of the connection to validate.
 * @returns {boolean} - Returns true if valid, false otherwise.
 */
function validateConnectionName(connectionName) {
    if (!connectionName || connectionName.trim() === '') {
      return false;
    }
  
    const regex = /^[a-zA-Z0-9_-]+$/;
    return regex.test(connectionName);
  }
  
  module.exports = {
    validateConnectionName,
  };