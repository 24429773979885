// src/components/connectors/ConnectorsGrid.js
import React from 'react';
import ConnectorCard from './ConnectorCard';
import { Box } from '../../libs/materialui/MaterialUI';

const ConnectorsGrid = ({ connectors, onConnectorClick }) => (
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: 2,
      transition: 'all 0.3s ease',
    }}
  >
    {connectors.map((connector, index) => (
  <ConnectorCard
    key={connector.id || index} // Use 'id' if available, else fallback to index
    connector={connector}
    onClick={onConnectorClick}
    isMatch={connector.isMatch}
  />
))}
  </Box>
);

export default ConnectorsGrid;