// src/components/common/LogoutButton.js
import React from 'react';
import { ListItemButton, ListItemText } from '../../libs/materialui/MaterialUI';
import { signOut } from 'firebase/auth';
import { auth } from '../../services/firebase/firebaseService';
import { useNavigate } from 'react-router-dom';

const LogoutButton = ({ isLoggingOut, setIsLoggingOut }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    if (isLoggingOut) return; // Prevent multiple clicks
    setIsLoggingOut(true);
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <ListItemButton onClick={handleLogout} sx={{ pl: 4 }} disabled={isLoggingOut}>
      <ListItemText primary={isLoggingOut ? 'Logging out...' : 'Logout'} />
    </ListItemButton>
  );
};

export default LogoutButton;