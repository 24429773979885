// src/components/navigation/LeftMenu.js
import React, { useState } from 'react';
import { 
  Box, 
  Stack, 
  MenuButton, 
  List 
} from '../../libs/materialui/MaterialUI';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import AccountSubMenu from './LeftMenu/AccountSubMenu';
import MENU_ITEMS from './MenuItems';

const LeftMenu = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        width: 240,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.paper',
      }}
    >
      <Box sx={{ p: 2, flexGrow: 1 }}>
        <Stack spacing={1} direction="column">
          {MENU_ITEMS.map(({ id, path, label, icon }) => (
            <Box
              key={id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: location.pathname === path ? 
                  (theme) => `${theme.palette.primary.main}20` : 'transparent',
                '&:hover': {
                  backgroundColor: (theme) => 
                    location.pathname === path ? 
                    `${theme.palette.primary.main}30` : 
                    `${theme.palette.primary.light}20`,
                }
              }}
            >
              <MenuButton
                onClick={() => navigate(path)}
                sx={{ width: '100%' }}
              >
                {icon}
                <Box component="span" sx={{ ml: 2 }}>
                  {label}
                </Box>
              </MenuButton>
            </Box>
          ))}
        </Stack>
      </Box>
      {currentUser && (
        <Box sx={{ p: 2, borderTop: '1px solid', borderColor: 'divider', bgcolor: 'neutral' }}>
          <List component="nav">
            <AccountSubMenu 
              open={open} 
              handleClick={handleClick} 
              isLoggingOut={isLoggingOut} 
              setIsLoggingOut={setIsLoggingOut} 
            />
          </List>
        </Box>
      )}
    </Box>
  );
};

export default LeftMenu;