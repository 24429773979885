import React from 'react';
import PageLayout from '../../components/layout/PageLayout';
import GridLayout from '../../components/layout/GridLayout';
import LeftMenu from '../../components/navigation/LeftMenu';
import { Button, TextField } from '../../libs/materialui/MaterialUI';

const Home = () => {
  const section1 = (
    <div>
      <h1>Section 1</h1>
      <form>
        <GridLayout
          items={[
            <TextField label="Email" name="email" autoComplete="email" />,
            <TextField label="Password" name="password" type="password" autoComplete="current-password" />,
            <Button type="submit">Submit</Button>
          ]}
        />
      </form>
    </div>
  );

  const section2 = (
    <div>
      <h1>Section 2</h1>
      <p>This is content for the second column. You can put any elements here.</p>
      <p>Additional content for the second column.</p>
    </div>
  );

  return (
    <PageLayout leftMenu={<LeftMenu />}>
      <GridLayout items={[section1, section2]} />
    </PageLayout>
  );
};

export default Home;