// src/components/common/navigation/leftmenu/AccountSubMenu.js
import React from 'react';
import { 
  ListItemButton, 
  ListItemText, 
  Collapse, 
  List 
} from '../../../libs/materialui/MaterialUI';
import { AccountCircle as AccountIcon, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import LogoutButton from '../../common/LogoutButton';

const AccountSubMenu = ({ open, handleClick, isLoggingOut, setIsLoggingOut }) => {
  const navigate = useNavigate();

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <AccountIcon sx={{ mr: 2 }} />
        <ListItemText primary="Account" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/account')}>
            <ListItemText primary="Account" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/support')}>
            <ListItemText primary="Support" />
          </ListItemButton>
          <LogoutButton isLoggingOut={isLoggingOut} setIsLoggingOut={setIsLoggingOut} />
        </List>
      </Collapse>
    </>
  );
};

export default AccountSubMenu;