// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#201533',
      light: '#252446',
      dark: '#3b0f87',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#b2d942',
      light: '#52c33f',
      dark: '#9a0036',
      contrastText: '#ffffff'
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2'
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c'
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00'
    },
    neutral: {
      main: '#64748B',
      light: '#94a3b8',
      dark: '#475569'
    }
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  }
});

export default theme;