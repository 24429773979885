// src/routes/AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
// public pages
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import Register from '../pages/Register/Register';
import Contact from '../pages/Contact/Contact';
import LoginConfirm from '../pages/Login/LoginConfirm';
import RegisterConfirm from '../pages/Register/RegisterConfirm';
// logged-in pages
import Console from '../pages/Console/Console';
import Account from '../pages/Account/Account';
import Support from '../pages/Support/Support';
import Connectors from '../pages/Connectors/Connectors';
import Data from '../pages/Data/Data';
import HowTo from '../pages/HowTo/HowTo';

const AppRouter = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/confirm" element={<LoginConfirm />} />
      <Route path="/register" element={<Register />} />
      <Route path="/register/confirm" element={<RegisterConfirm />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/console" element={
        <PrivateRoute>
          <Console />
        </PrivateRoute>
      } />
      <Route path="/account" element={<Account />} /> {/* // TODO -  make private route */}
      <Route path="/support" element={<Support />} /> {/* // TODO -  make private route */}
      <Route path="/connectors" element={
        <PrivateRoute>
          <Connectors />
        </PrivateRoute>
      } /> 
      <Route path="/data" element={<Data />} /> {/* // TODO -  make private route */}
      <Route path="/howto" element={<HowTo />} /> {/* // TODO -  make private route */}
    </Routes>
  </Router>
);

export default AppRouter;