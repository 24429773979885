// src/components/common/Header/Header.js
import React from 'react';
import { 
  AppBar, 
  Toolbar,
  Box,
  IconButton,
  useMediaQuery
} from '../../../libs/materialui/MaterialUI';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Title } from './styles';
import UserMenu from './UserMenu';

const Header = ({ onMenuToggle, onDesktopMenuToggle, isMenuOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        zIndex: (theme) => theme.zIndex.drawer - 1,
        ml: { 
          sm: isMenuOpen ? '240px' : '0px'
        },
        width: { 
          sm: isMenuOpen ? `calc(100% - 240px)` : `100%`
        },
        transition: 'margin-left 0.3s ease, width 0.3s ease'
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="toggle drawer"
          edge="start"
          onClick={isMobile ? onMenuToggle : onDesktopMenuToggle}
          sx={{ mr: 2 }}
        >
          {isMenuOpen && !isMobile ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
        
        <Title variant="h6">Simple Access</Title>
        
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1
        }}>
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;