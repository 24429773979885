// src/components/connectors/ConnectorsHeader.js
import React from 'react';
import { Box, Button, Typography } from '../../libs/materialui/MaterialUI';

const ConnectorsHeader = ({ onNewConnectorClick }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
    <Typography variant="h4">Connectors</Typography>
    <Button variant="contained" color="primary" onClick={onNewConnectorClick}>
      + New Connector
    </Button>
  </Box>
);

export default ConnectorsHeader;