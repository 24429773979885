// src/pages/Login/Login.js
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  Container,
  CircularProgress
} from '../../libs/materialui/MaterialUI';
import { useNavigate } from 'react-router-dom';
import { sendSignInLinkToEmail, getAuth } from 'firebase/auth';
import { getAuthErrorMessage } from '../../utils/authErrors';
import CustomSnackbar from '../../components/ui/CustomSnackbar';
import { StyledPaper } from '../../components/ui/StyledPaper';

const Login = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate('/');
      }
    });
    return () => unsubscribe();
  }, [auth, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const actionCodeSettings = {
      url: `${window.location.origin}/login/confirm`,
      handleCodeInApp: true
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setInfo('Check your email for the login link!');
    } catch (error) {
      setError(getAuthErrorMessage(error.code));
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setError('');
    setInfo('');
  };

  return (
    <Container maxWidth="xs">
      <StyledPaper>
        <Typography variant="h4" component="h1">
          Login
        </Typography>
        <Box component="form" onSubmit={handleLogin} sx={{ width: '100%' }}>
          <TextField
            fullWidth
            margin="normal"
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Send Login Link'}
          </Button>
        </Box>
        <Button
          variant="text"
          onClick={() => navigate('/register')}
          disabled={loading}
        >
          Need an account? Register
        </Button>
      </StyledPaper>

      <CustomSnackbar
        open={!!error || !!info}
        message={error || info}
        severity={error ? 'error' : 'success'}
        onClose={handleCloseSnackbar}
      />
    </Container>
  );
};

export default Login;