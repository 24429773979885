// src/components/connectors/ConnectorModal.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '../../libs/materialui/MaterialUI';
import { useAuthUser } from '../../contexts/AuthUserContext';
import { checkConnectionExists } from '../../services/firestore/firestoreService';

const ConnectorModal = React.forwardRef(({ connector, open, onClose, teams }, ref) => {
  const { currentUser, loading, getIdToken } = useAuthUser();
  const [selectedTeam, setSelectedTeam] = useState('');
  const [connectionName, setConnectionName] = useState('');
  const [error, setError] = useState('');
  const [connectionNameError, setConnectionNameError] = useState('');

  useEffect(() => {
    if (teams && teams.length > 0) {
      setSelectedTeam(teams[0].id);
    }
  }, [teams]);

  const handleTeamChange = (event) => {
    setSelectedTeam(event.target.value);
    if (event.target.value) {
      setError('');
    }
  };

  const validateConnectionName = async (name) => {
    try {
      const isValid = await checkConnectionExists(selectedTeam, connector.name, name);
      if (isValid) {
        setConnectionNameError('A connection with this name already exists.');
        return false;
      }
      setConnectionNameError('');
      return true;
    } catch (err) {
      setConnectionNameError('Invalid connection name. Only alphanumeric characters, underscores, and dashes are allowed.');
      return false;
    }
  };


  const handleConnectionNameChange = async (event) => {
    const name = event.target.value;
    setConnectionName(name);
    if (name) {
      await validateConnectionName(name);
    } else {
      setConnectionNameError('Connection name is required.');
    }
  };

  const handleAuthorize = async () => {
    if (loading) {
      console.log('Loading user information...');
      return;
    }

    if (!currentUser) {
      console.error('User not authenticated.');
      return;
    }

    if (!selectedTeam) {
      setError('Please select a team.');
      return;
    }

    if (!connectionName) {
      setConnectionNameError('Please enter a connection name.');
      return;
    }

    const isValid = await validateConnectionName(connectionName);
    if (!isValid) {
      return;
    }

    const idToken = await getIdToken();
    const state = encodeURIComponent(
      `userId=${currentUser.uid}&teamId=${selectedTeam}&connector=${connector?.name}&redirect_uri=${window.location.href}&token=${idToken}&connectionName=${connectionName}`
    );

    if (connector && connector.authUrl) {
      const authUrl = `${connector.authUrl}&state=${state}`;
      window.location.href = authUrl;
    } else {
      console.error('Authorization URL is not available.');
    }
  };

  if (!connector) {
    return null; // Or display a fallback UI
  }

  return (
    <Modal open={open} onClose={onClose} ref={ref}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Authorize {connector.name}
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel id="team-select-label">Team</InputLabel>
          <Select
            labelId="team-select-label"
            value={selectedTeam}
            label="Team"
            onChange={handleTeamChange}
          >
            {teams.map((team) => (
              <MenuItem key={team.id} value={team.id}>
                {team.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Connection Name"
          variant="outlined"
          fullWidth
          value={connectionName}
          onChange={handleConnectionNameChange}
          error={Boolean(connectionNameError)}
          helperText={connectionNameError}
          margin="normal"
        />
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleAuthorize}
          disabled={loading}
          fullWidth
          sx={{ mt: 2 }}
        >
          Authorize
        </Button>
      </Box>
    </Modal>
  );
});

export default ConnectorModal;