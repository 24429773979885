// src/libs/MaterialUI.js

import React from 'react';
import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Typography as MuiTypography,
  Box as MuiBox,
  Container as MuiContainer,
  Stack as MuiStack,
  Button as MuiButton,
  TextField as MuiTextField,
  Grid2 as MuiGrid2,
  IconButton as MuiIconButton,
  useMediaQuery as MuiUseMediaQuery,
  CircularProgress as MuiCircularProgress,
  Paper as MuiPaper,
  Snackbar as MuiSnackbar,
  Alert as MuiAlert,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
  Collapse as MuiCollapse,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Divider as MuiDivider,
  Modal as MuiModal,
  Drawer as MuiDrawer,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  Select as MuiSelect,
} from '@mui/material';

import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  Help as HelpIcon,
  Storage as StorageIcon,
  Visibility as VisibilityIcon,
  Support as SupportIcon,
  AccountCircle as AccountIcon,
  Close as CloseIcon,
  MenuOpen as MenuOpenIcon,
} from '@mui/icons-material';

import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import styled from '@emotion/styled';
import theme from './theme';

// Helper Function
const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{3})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
    : null;
};

// ---------------------
// Material-UI Wrappers
// ---------------------

export const AppBar = (props) => <MuiAppBar {...props} />;
export const Toolbar = (props) => <MuiToolbar {...props} />;
export const Typography = React.forwardRef((props, ref) => (
  <MuiTypography ref={ref} {...props} />
));
export const Box = React.forwardRef((props, ref) => (
  <MuiBox ref={ref} {...props} />
));
export const Container = (props) => <MuiContainer {...props} />;
export const Stack = (props) => (
  <MuiStack {...props} direction={props.direction || 'row'} spacing={2} />
);
export const Grid = ({ item, ...props }) => (
  <MuiGrid2 item={item ? 1 : undefined} {...props} />
);
export const Button = React.forwardRef((props, ref) => (
  <MuiButton ref={ref} variant="contained" color="primary" {...props} />
));
export const useMediaQuery = MuiUseMediaQuery;
export const CircularProgress = (props) => <MuiCircularProgress {...props} />;
export const Paper = (props) => <MuiPaper {...props} />;
export const Snackbar = (props) => <MuiSnackbar {...props} />;
export const Alert = (props) => <MuiAlert {...props} />;
export const List = (props) => <MuiList {...props} />;
export const ListItem = (props) => <MuiListItem {...props} />;
export const ListItemText = (props) => <MuiListItemText {...props} />;
export const ListItemButton = (props) => <MuiListItemButton {...props} />;
export const Collapse = (props) => <MuiCollapse {...props} />;
export const Menu = (props) => <MuiMenu {...props} />;
export const MenuItem = (props) => <MuiMenuItem {...props} />;
export const Divider = (props) => <MuiDivider {...props} />;
export const Modal = React.forwardRef((props, ref) => (
  <MuiModal ref={ref} {...props} />
));
export const Drawer = (props) => <MuiDrawer {...props} />;
export const FormControl = (props) => <MuiFormControl {...props} />;
export const InputLabel = (props) => <MuiInputLabel {...props} />;
export const Select = (props) => <MuiSelect {...props} />;

// ---------------------
// Styled Components
// ---------------------

export const AppMenuButton = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '6px 16px',
  backgroundColor: 'transparent',
  border: `2px solid ${theme.palette.secondary.contrastText}`,
  color: theme.palette.secondary.contrastText,
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 8px rgba(${hexToRgb(theme.palette.secondary.main)}, 0.25)`,
  },
}));

export const MenuButton = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '12px 24px',
  textTransform: 'none',
  color: theme.palette.primary.main,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: `${theme.palette.primary.light}20`,
  },
  '& .MuiSvgIcon-root': {
    marginRight: '12px',
  },
}));

export const SubMenuButton = styled('button')(({ theme, disabled }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '8px 16px',
  textTransform: 'none',
  color: theme.palette.primary.dark,
  backgroundColor: 'transparent',
  border: 'none',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? 0.6 : 1,
  '&:hover': {
    backgroundColor: disabled ? 'transparent' : `${theme.palette.primary.light}20`,
  },
  '&:focus': {
    outline: 'none',
  },
  '& .MuiSvgIcon-root': {
    marginRight: '8px',
  },
}));

export const TextField = (props) => (
  <MuiTextField variant="outlined" fullWidth {...props} />
);

// ---------------------
// Theme Provider
// ---------------------

export const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

// ---------------------
// Icons
// ---------------------

export {
  MenuIcon,
  DashboardIcon,
  HelpIcon,
  StorageIcon,
  VisibilityIcon,
  SupportIcon,
  AccountIcon,
  CloseIcon,
  MenuOpenIcon,
};

// ---------------------
// Additional Components
// ---------------------

export const IconButton = React.forwardRef((props, ref) => (
  <MuiIconButton ref={ref} {...props} />
));

// ---------------------
// Export Styled
// ---------------------

export { styled };

// ---------------------
// Export cssbaseline
// ---------------------

export { CssBaseline };