import React from 'react';
import useAuth from '../../hooks/useAuth';

const Console = () => {
  const { userData } = useAuth();

  return (
    <div>
      <h1>Console Page</h1>
      <p>Welcome, {userData.email}</p>
    </div>
  );
};

export default Console;