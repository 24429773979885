import { initializeApp } from "firebase/app";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth"; 
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

console.log("Firebase: Initializing");
const app = initializeApp(firebaseConfig);
console.log("Firebase: App initialized");

const auth = getAuth(app);
console.log("Firebase: Auth initialized");

const db = getFirestore(app); // Initialize Firestore
console.log("Firebase: Firestore initialized");
console.log("Firebase: Initialized");

// Enable Firestore logging
//setLogLevel('debug');  // This will provide verbose logs

export { app, auth, db };

export const handleEmailLink = async () => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    let email = window.localStorage.getItem('emailForSignIn');
    
    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }

    try {
      const result = await signInWithEmailLink(auth, email, window.location.href);
      window.localStorage.removeItem('emailForSignIn');
      // could add any user data to the database here
      return result;
    } catch (error) {
      console.error('Error signing in with email link:', error);
      throw error;
    }
  }
};