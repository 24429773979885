// src/components/ui/CustomSnackbar.js
import React from 'react';
import { Snackbar, Alert } from '../../libs/materialui/MaterialUI';

const CustomSnackbar = ({ open, message, severity, onClose }) => {
  return (
    <div style={{ position: 'fixed', bottom: 24, left: '50%', transform: 'translateX(-50%)' }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        style={{ position: 'static', transform: 'none' }}
        TransitionComponent={(props) => <div {...props} />} // Disable transition
      >
        <Alert 
          elevation={6} 
          variant="filled" 
          onClose={onClose} 
          severity={severity}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomSnackbar;