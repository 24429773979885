// src/components/connectors/ConnectorCard.js
import React from 'react';
import { Box, Typography } from '../../libs/materialui/MaterialUI';

const ConnectorCard = ({ connector, onClick, isMatch }) => (
  <Box
    onClick={() => onClick(connector)}
    sx={{
      bgcolor: isMatch ? 'secondary.main' : 'neutral.light',
      border: '1px solid #ccc',
      borderRadius: 2,
      padding: 2,
      cursor: 'pointer',
      transition: 'background-color 0.3s ease, transform 0.3s ease',
      '&:hover': { 
        bgcolor: isMatch ? 'secondary.light' : 'neutral.main', 
        boxShadow: 2,
        transform: 'scale(1.05)',
      },
      width: 200,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Typography variant="h6" color={'common.white'}>
      {connector.name}
    </Typography>
  </Box>
);

export default ConnectorCard;