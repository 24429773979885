// src/contexts/AuthUserContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from '../services/firebase/firebaseService';
import { doc, onSnapshot } from 'firebase/firestore';
import { getUserTeams } from '../services/firestore/firestoreService';

export const AuthUserContext = createContext();

export const AuthUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      setLoading(false);
      if (user) {
        try {
          const userTeams = await getUserTeams(user);
          setTeams(userTeams);
        } catch (error) {
          console.error('Error fetching user teams:', error);
        }
      } else {
        setTeams([]);
      }
    });

    return unsubscribeAuth;
  }, []);

  useEffect(() => {
    if (loading) return;

    if (!currentUser) {
      console.log('No current user.');
      return;
    }

    const userDocRef = doc(db, 'users', currentUser.uid);
    const unsubscribeUser = onSnapshot(
      userDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setUserData(docSnapshot.data());
        } else {
          setUserData(null);
        }
      },
      (error) => {
        console.error('Error fetching user data:', error);
      }
    );

    return () => unsubscribeUser();
  }, [currentUser, loading]);

  const getIdToken = async () => {
    if (currentUser) {
      return await currentUser.getIdToken();
    }
    return null;
  };

  return (
    <AuthUserContext.Provider value={{ currentUser, userData, teams, getIdToken }}>
      {!loading && children}
    </AuthUserContext.Provider>
  );
};

export const useAuthUser = () => useContext(AuthUserContext);