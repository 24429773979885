// src/libs/firestore/firestoreService.js
import { db } from '../firebase/firebaseService';
import { collection, doc, getDoc, query, where, getDocs } from 'firebase/firestore';
import { validateConnectionName } from '../../utils/validation';

export const getUserData = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      throw new Error('No such user!');
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const getUserTeams = async (userId) => {
  const user = userId;
  if (!user) {
    throw new Error('User not authenticated.');
  }
  const teamsRef = collection(db, 'teams');
  const q = query(teamsRef, where('members', 'array-contains', user.uid));
  const querySnapshot = await getDocs(q);
  const teams = [];
  querySnapshot.forEach(doc => {
    teams.push({ id: doc.id, ...doc.data() });
  });
  return teams;
};

export const getConnectors = async () => {
  try {
    const connectorsQuery = query(
      collection(db, 'connectors'),
      where('deployed', '==', true)
    );
    const querySnapshot = await getDocs(connectorsQuery);
    return querySnapshot.docs.map(doc => doc.data());
  } catch (error) {
    console.error('Error fetching connectors:', error);
    throw error;
  }
};

/**
 * Checks if a connection with the given name exists under the specified team and connector.
 *
 * @param {string} teamId - The ID of the team.
 * @param {string} connectorName - The name of the connector.
 * @param {string} connectionName - The name of the connection to check.
 * @returns {Promise<boolean>} - Returns true if the connection exists, otherwise false.
 */
export const checkConnectionExists = async (teamId, connectorName, connectionName) => {
  if (!teamId || !connectorName || !connectionName) {
    return false;
  }

  // Validate connectionName before checking
  if (!validateConnectionName(connectionName)) {
    throw new Error('Invalid connection name.');
  }
  
  try {
    const connectionRef = doc(
      db,
      'teams',
      teamId,
      'connectors',
      connectorName,
      'connections',
      connectionName
    );
    const docSnap = await getDoc(connectionRef);
    return docSnap.exists();
  } catch (error) {
    console.error('Error checking connection existence:', error);
    throw error;
  }
};