// src/pages/Login/LoginConfirm.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, CircularProgress, Typography, Button } from '@mui/material';
import { handleEmailLink } from '../../services/firebase/firebaseService';

const LoginConfirm = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useEffect(() => {
    const completeSignIn = async () => {
      try {
        const result = await handleEmailLink();
        if (result?.user) {
          navigate('/');
        } else {
          setError('Authentication failed');
        }
      } catch (err) {
        console.error('Sign in error:', err);
        setError(err.message);
      }
    };
    completeSignIn();
  }, [navigate]);

  return (
    <Container sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '50vh',
      gap: 2
    }}>
      {error ? (
        <>
          <Typography color="error">{error}</Typography>
          <Button 
            variant="contained" 
            onClick={() => navigate('/login')}
          >
            Return to Login
          </Button>
        </>
      ) : (
        <CircularProgress />
      )}
    </Container>
  );
};

export default LoginConfirm;