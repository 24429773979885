import React from 'react';
import { AuthUserProvider } from './contexts/AuthUserContext';
import AppRouter from './routes/AppRouter';

const App = () => (
  <AuthUserProvider>
    <AppRouter />
  </AuthUserProvider>
);

export default App;