// src/components/navigation/Header/UserMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import { AppMenuButton, Typography, styled } from '../../../libs/materialui/MaterialUI';
import useAuth from '../../../hooks/useAuth';
import LogoutButton from '../../common/LogoutButton';

const StyledRouterLink = styled(Link)({
  color: 'white',
  textDecoration: 'none',
  marginLeft: '20px',
});

const UserMenu = () => {
  const { currentUser, userData } = useAuth();
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);

  if (!currentUser) {
    return (
      <StyledRouterLink to="/login">
        <AppMenuButton>Login</AppMenuButton>
      </StyledRouterLink>
    );
  }

  return (
    <>
      <Typography sx={{ ml: 2, mr: 2 }}>
        Hi, {userData?.displayName || currentUser.email}
      </Typography>
      <LogoutButton isLoggingOut={isLoggingOut} setIsLoggingOut={setIsLoggingOut} />
    </>
  );
};

export default UserMenu;