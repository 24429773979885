// src/components/PageLayout/PageLayout.js
import React from 'react';
import { Box, Container, CssBaseline, Drawer, Toolbar } from '../../libs/materialui/MaterialUI';
import Header from '../navigation/Header/Header';
import '../../assets/styles/layout.css';

const PageLayout = ({ children, leftMenu }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(true); // Desktop default: open
  const [mobileOpen, setMobileOpen] = React.useState(false); // Mobile default: closed

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDesktopMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        {leftMenu && (
          <>
            {/* Desktop version */}
            <Box
              className="left-menu"
              sx={{
                display: { xs: 'none', sm: 'block' },
                width: isMenuOpen ? 240 : 0, // Adjust minimized width as needed
                overflowX: 'hidden',
                transition: 'width 0.3s ease-in-out',
                height: '100vh',
                flexShrink: 0
              }}
            >
              {leftMenu}
            </Box>

            {/* Mobile version */}
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
              }}
            >
              {leftMenu}
            </Drawer>
          </>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Header 
            onMenuToggle={handleDrawerToggle} 
            onDesktopMenuToggle={handleDesktopMenuToggle}
            isMenuOpen={isMenuOpen}
          />
          <Toolbar /> {/* Ensures content is below the AppBar */}
          <Container sx={{ flexGrow: 1, mt: 2, }}>
            {children}
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default PageLayout;