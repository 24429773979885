// src/components/common/Header/styles.js
import { styled } from '@mui/material/styles';
import { Typography, Link } from '@mui/material';

export const Title = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  '@media (max-width: 600px)': {
    fontSize: '1.1rem',
  },
}));

export const StyledLink = styled(Link)({
  color: 'white',
  textDecoration: 'none',
  marginLeft: '20px',
});