// src/pages/Connectors.js
import React, { useEffect, useState, useRef } from 'react';
import { TextField, Typography } from '../../libs/materialui/MaterialUI';
import PageLayout from '../../components/layout/PageLayout';
import LeftMenu from '../../components/navigation/LeftMenu';
import { getConnectors } from '../../services/firestore/firestoreService';
import ConnectorsHeader from '../../components/connectors/ConnectorsHeader';
import ConnectorsGrid from '../../components/connectors/ConnectorsGrid';
import ConnectorModal from '../../components/connectors/ConnectorModal';
import useAuth from '../../hooks/useAuth'; // Import useAuth

const Connectors = () => {
  const { teams } = useAuth(); // Get teams from useAuth
  const [connectors, setConnectors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortedConnectors, setSortedConnectors] = useState([]);
  const [selectedConnector, setSelectedConnector] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const searchBoxRef = useRef(null);

  useEffect(() => {
    const fetchConnectors = async () => {
      try {
        const data = await getConnectors();
        setConnectors(data);
        setSortedConnectors(data);
      } catch (error) {
        console.error('Error fetching connectors:', error);
      }
    };
    fetchConnectors();
  }, []);

  useEffect(() => {
    const query = searchQuery.toLowerCase();
    const updatedConnectors = connectors
      .map((connector) => ({
        ...connector,
        isMatch: connector.name.toLowerCase().includes(query),
      }))
      .sort((a, b) => b.isMatch - a.isMatch);

    setSortedConnectors(updatedConnectors);
  }, [searchQuery, connectors]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleNewConnectorClick = () => {
    if (searchBoxRef.current) {
      searchBoxRef.current.focus();
    } else {
      console.error('Search box ref is null');
    }
  };

  const handleConnectorClick = (connector) => {
    setSelectedConnector(connector);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedConnector(null);
  };

  return (
    <PageLayout leftMenu={<LeftMenu />}>
      <ConnectorsHeader onNewConnectorClick={handleNewConnectorClick} />
      <Typography variant="body1" mb={2}>
        You have no existing connections setup.
      </Typography>
      <TextField
        label="Search Connectors"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearch}
        margin="normal"
        inputRef={searchBoxRef}
      />
      <ConnectorsGrid connectors={sortedConnectors} onConnectorClick={handleConnectorClick} />

      <ConnectorModal
        open={isModalOpen}
        onClose={handleCloseModal}
        connector={selectedConnector}
        teams={teams} // Pass teams from context
      />
    </PageLayout>
  );
};

export default Connectors;