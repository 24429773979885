import React, { useEffect, useState, useRef } from 'react';
import { Container, Typography, Button, CircularProgress } from '../../libs/materialui/MaterialUI';
import { useNavigate } from 'react-router-dom';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';

const RegisterConfirm = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current) return;

    const completeRegistration = async () => {
      if (!isSignInWithEmailLink(auth, window.location.href)) {
        setError('Invalid or expired registration link');
        return;
      }

      const email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        setError('Please register again - email not found');
        return;
      }

      try {
        console.log('Attempting to complete registration for:', email);
        
        await auth.signOut();
        
        const result = await signInWithEmailLink(auth, email, window.location.href);
        console.log('Registration completed:', result.user.uid);
        
        window.localStorage.removeItem('emailForSignIn');
        
        navigate('/console');
      } catch (err) {
        console.error('Registration confirmation error:', err);
        setError(err.message);
      }
    };

    completeRegistration();

    return () => {
      effectRan.current = true;
    };
  }, [auth, navigate]);

  return (
    <Container sx={{ 
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '50vh',
      gap: 2
    }}>
      {error ? (
        <>
          <Typography color="error">{error}</Typography>
          <Button 
            variant="contained" 
            onClick={() => navigate('/register')}
          >
            Return to Register
          </Button>
        </>
      ) : (
        <CircularProgress />
      )}
    </Container>
  );
};

export default RegisterConfirm;