import React from 'react';
import { Grid } from '../../libs/materialui/MaterialUI';

const GridLayout = ({ items }) => (
  <Grid container spacing={2}>
    {items.map((item, index) => (
      <Grid item key={index} xs={12} sm={6}>
        {item}
      </Grid>
    ))}
  </Grid>
);

export default GridLayout;