import React from 'react';
import PageLayout from '../../components/layout/PageLayout';
import GridLayout from '../../components/layout/GridLayout';
import LeftMenu from '../../components/navigation/LeftMenu';

const Home = () => {
  const section1 = (
    <div>
      <h1>Support</h1>
    </div>
  );

  return (
    <PageLayout leftMenu={<LeftMenu />}>
      <GridLayout items={[section1]} />
    </PageLayout>
  );
};

export default Home;