import React from 'react';
import { HelpIcon, StorageIcon, VisibilityIcon, SupportIcon } from '../../libs/materialui/MaterialUI';

const MENU_ITEMS = [
    { id: 'howto', path: '/howto', label: 'How To', icon: <HelpIcon /> },
    { id: 'connectors', path: '/connectors', label: 'Connectors', icon: <StorageIcon /> },
    { id: 'data', path: '/data', label: 'View Data', icon: <VisibilityIcon /> },
    { id: 'support', path: '/support', label: 'Support', icon: <SupportIcon /> }
  ];

export default MENU_ITEMS;